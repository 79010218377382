<template>
  <div class="bg">
    <!-- <van-search
      v-model="value"
      show-action
      placeholder="请输入搜索关键词"
      @search="onSearch"
    >
      <template #action>
        <span class="em" @click="changelocation">
          <van-icon name="location-o" /> <em>武汉</em></span>
      </template>
    </van-search>

    <div class="screen mb8">
      <van-dropdown-menu>
        <van-dropdown-item title="类型" v-model="value1" :options="option1" />
        <van-dropdown-item title="医院级别" v-model="value2" :options="option2" />
      </van-dropdown-menu>
    </div> -->
    <!-- 医院顶部修改 -->

    <div class="top_fixed">
      <van-search
        v-model="value"
        placeholder="请输入搜索关键词"
        input-align = "center"
        left-icon = 'none'
        @search="onSearch"
      >
      </van-search>
      <div class="choseitem">
        <div class="left">
          <img src="../../../assets/icon/position.png" alt="">
          <p>武汉</p>
        </div>
        <div class="right" @click="isShowAlert=!isShowAlert">
          <img src="../../../assets/icon/screen.png" alt="">
          <p>筛选</p>
        </div>
      </div>
    </div>
    <div class="alert_bg" v-if="isShowAlert"></div>
    <div class="alert" v-if="isShowAlert">
      <p>类型</p>
      <ul class="clearfloat">
        <li :class="value1==item.value?'on':''" v-for="item in option1" :key="item">{{item.text}}</li>
      </ul>
      <p>医院等级</p>
      <ul class="clearfloat">
        <li :class="value2==item.value?'on':''" v-for="item in option2" :key="item">{{item.text}}</li>
      </ul>
      <div class="click_button">
        <button>重置</button>
        <button class="on">确定</button>
      </div>
    </div>
    <div class="list">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div v-for="item in list" class="mylist" :key="item" @click="gohospitalindex">
            <img src="../../../assets/img/inquirying_2.png" alt="" />
            <div>
              <p>中国人民解放军总医院301...</p>
              <p class="f14">综合医院</p>
              <p><span>35科室</span><span>350位医生</span></p>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value: "",
      value1: 0,
      value2: "a",
      option1: [
        { text: "全部", value: 0 },
        { text: "综合医院", value: 1 },
        { text: "专业医院", value: 2 },
        { text: "慢性病医院", value: 2 },
      ],
      option2: [
        { text: "全部", value: "a" },
        { text: "三甲医院", value: "b" },
        { text: "三甲医院", value: "c" },
        { text: "三甲医院", value: "c" },
        { text: "三甲医院", value: "c" },
      ],
      list: [],
      isShowAlert: false,
      loading: false,
      finished: false,
      refreshing: false,
    };
  },
  methods: {
    // 医院首页
    gohospitalindex(){
       this.$router.push({ name: "hospitalindex" });

    },
    onSearch() {},
    changelocation() {
      console.log("切换地址");
    },
    onLoad() {
      setTimeout(() => {
        if (this.refreshing) {
          this.list = [];
          this.refreshing = false;
        }

        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }
        this.loading = false;

        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .van-search__content { border-radius: 18px;}
.bg {
  .list {
    background: #fff;
    padding: 16px;
    margin-top: 104px;
    .mylist {
      display: flex;
      padding-bottom: 16px;
      border-bottom: 1px solid #EFEFEF;
      margin-bottom: 17px;
      p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        margin-bottom: 8px;
        font-size: 16px;
        text-align: left;
        &.f14{
          font-size: 14px;
        }
        span{
          margin-right: 32px;
          font-size: 14px;

        }
      }
      img {
        display: block;
        width: 120px;
        height: 90px;
        border-radius: 8px;
        margin-right: 10px;
      }
    }
  }
  .em {
    font-size: 14px;
    em {
      font-style: normal;
    }
  }
  .screen {
    text-align: left;
    // padding: 16px;
    background: #fff;
    // display: flex;
  }
  .choseitem {
    background: #fff;
    margin-bottom: 8px;
    height: 52px;
    line-height: 52px;
    .left { float: left;
      img { width: 20px; height: 20px; display: block; float: left; margin: 16px 8px 16px 17px; }
      p { font-size: 14px; height: 52px; line-height: 52px; color: #C2C2C2; float: left;}  
    } 
    .right { float: right; margin-right: 17px;
      img { width: 20px; height: 20px; display: block; float: left; margin: 16px 8px 16px 17px; }
      p { font-size: 14px; height: 52px; line-height: 52px; color: #C2C2C2; float: left;}  
    } 
  }
  .top_fixed { position: fixed; top: 0; left: 0; background-color: #fff; width: 100%; height: 104px; z-index: 9;} 
  .alert_bg { 
    position: fixed; background: rgba(0,0,0,.4); width: 100vw; height: calc(100vh - 104px); bottom: 0; left: 0; z-index: 9;
  }
  .alert { 
    position: fixed; background: #FFF; width: 100vw; max-height: calc(100vh - 104px); top: 104px; left: 0; border-top: 1px solid #EFEFEF; z-index: 9;
    p { font-size: 14px; color: #2D2D2D; height: 52px; line-height: 52px; padding: 0 16px;}
    ul { padding: 0 16px;
      li { width: auto; height: 44px; border-radius: 9px; border: 1px solid #C2C2C2; padding: 0 20px; line-height: 42px; font-size: 14px; color:#444444; float: left; margin-bottom: 10px; margin-right: 10px;}
      li.on { border-color: #1DAEFC; color: #1DAEFC;}  
    } 
    .click_button { display: flex; justify-content: center; margin-top: 22px;} 
    button { width: auto; height: 44px; border-radius: 9px; border: 1px solid #C2C2C2; padding: 0 20px; line-height: 42px; font-size: 14px; color:#444444; float: left; margin-bottom: 10px; margin-right: 10px; background: #fff; width: 96px;}
    button.on { color: #fff; border-color: #1DAEFC; background: #1DAEFC;}   
  } 
}
</style>
